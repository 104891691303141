<!-- 公司团队 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text10 }}</h3>
      </div>
    </div>
    <div class="container">
      <ul class="list">
        <template v-for="(item, index) in list">
          <template v-if="item.open">
            <li class="selected" @click="showDetails(index)">
              <div class="box">
                <img :src="`src/head_${index + 1}.png`" />
              </div>
              <div>
                <h3 v-html="item.name"></h3>
                <span>{{ item.title }}</span>
                <p>{{ item.desc }}</p>
              </div>
            </li>
          </template>
          <template v-else>
            <li @click="showDetails(index)">
              <div class="box">
                <img :src="`src/head_${index + 1}.png`" />
              </div>
              <div>
                <h3 v-html="item.name"></h3>
                <span>{{ item.title }}</span>
                <p>{{ item.desc }}</p>
              </div>
            </li>
          </template>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text10: "高管团队",
          list: [
            {
              open: false,
              name: "靳照宇",
              title: "董事长兼CEO",
              desc: `美国宾夕法尼亚大学药理学博士，北京市特聘专家，曾在基因泰克等生物制药公司领导项目团队，担任高管，在基础及转化医学研究、大分子药物开发和企业运营管理上积累了丰富的专业经验。曾以主要作者在"Cell"等世界一流期刊上发表多篇论文，引用率超过3600次。发明了以抗体智能设计及动态进化筛选为核心的创新抗体药物工程技术。领导开发了一系列针对恶性肿瘤，免疫疾病及病毒感染的抗体类药物。负责或参与过国家重点实验室研究课题、蛋白质机器重点专项、精准医学研究专项目等国家级科研项目。`,
            },
            {
              open: false,
              name: "李&emsp;芸",
              title: "总经理",
              desc: `美国宾夕法尼亚大学分子及细胞生物学博士，师从于著名的美国科学院院士。后进入世界知名生物制药公司基因泰克，主持参与过涉及肿瘤及免疫疾病治疗用单抗及双特异抗体等多个蛋白质药物的研究开发工作。拥有超过20年的创新抗体研发经验，擅长肿瘤治疗以及免疫类相关疾病的研究，曾以主要作者在"Cell","Nature"等世界一流期刊上发表多篇论文。`,
            },
            // {
            //   open: false,
            //   name: "杨亚平",
            //   title: "药品活性部总经理",
            //   desc: "毕业于北京大学-药理学专业。主要负责生物学机制、临床前药效药代和安全性评价，同时也负责部分转化医学工作。拥有逾13年的大分子药物开发经验，在生物活性和机制分析、药代动力学分析、体内药效评价以及毒性评价方面拥有丰富的专业知识和实践经验。在加入明济生物以前，曾在业界大型药企担任重要岗位。",
            // },
            // {
            //   open: false,
            //   name: "金秀梅",
            //   title: "质量管理&分析科学部高级总监",
            //   desc: "毕业于沈阳药科大学-药学专业。2018年加入明济生物，现任质量管理及分析科学部高级总监，主要负责质量控制，质量保证，分子评价分析及制剂工艺等工作。擅长提供总体的质控和分析技术指导，突破项目技术难点，尤其在大分子表征方面拥有丰富的经验。",
            // },
            // {
            //   open: false,
            //   name: "唐文英",
            //   title: "工艺及生产部高级总监",
            //   desc: "毕业于中国农业大学-生物化学与分子生物学专业。拥有十余年大分子生物药上下游工艺开发及中试放大工作经验，参与多个单抗，双抗，融合蛋白及细胞因子等项目的临床前CMC开发及中美IND申报工作，熟悉项目从DNA到IND及BLA CMC全流程，所参与项目已经进入临床一期、二期及三期研究阶段，积累了丰富的工作经验，擅长管理CMC开发整个流程和项目执行。",
            // },
            // {
            //   open: false,
            //   name: "周玉斌",
            //   title: "临床医学部总经理",
            //   desc: "毕业于北京大学-医学免疫学专业。12年大型制药界经历，对临床研究的设计和实施有具体的经验，带领团队通过临床试验并推动 8个产品上市，3个产品推进到NDA阶段。业内专家资源丰富，从临床专家到药理、检测、统计、CRO等合作广泛。",
            // },
            // {
            //   open: false,
            //   name: "黄思绮",
            //   title: "商务拓展部总经理",
            //   desc: "毕业于南加利福尼亚大学-药学专业。制药行业超过18年，完成超过10个药品上市及参与超过600多项药物评估和国内外药厂商务合作包括潜在投资、融资、并购项目及国际项目引进、转让、共同开发等经验。曾先后就职于Eli Lilly、Roche、Bayer、Takeda、Henlius、3SBio等，担任国内外药企临床研究、市场营销、新产品策划、战略规划、商务拓展等相关职位。",
            // },
            // {
            //   open: false,
            //   name: "邵斌",
            //   title: "人力资源部高级总监",
            //   desc: "毕业于香港大学组织与人力资源管理专业。主要负责公司人力资源管理、组织发展、企业宣传、行政管理工作。拥有逾13年人力资源相关从业经验，8年制药届经历，在人才梯队搭建，人员招聘，企业文化建设上拥有丰富经验。",
            // },
            // {
            //   open: false,
            //   name: "库建萍",
            //   title: "财务部副总监",
            //   desc: "毕业于中国石油大学会计学专业。20余年丰富的财务管理工作经验，有较强的财务内部控制、预算管理、现金流管理、财务分析、风险防范的实战能力。擅长IPO企业资本运作（上市筹划，企业重组，投融资等）、搭建、优化与企业经营业务相匹配的财务管理体系。",
            // },
          ],
        };
        break;
      case "en":
        return {
          text10: "Management Team",
          list: [
            {
              open: false,
              name: "Dr.Zhaoyu Jin",
              title: "Chairman and CEO",
              desc: `Dr. Jin is the distinguished expert specially invited by Beijing government, and has a Ph.D. in pharmacology from the University of Pennsylvania. He ever led project teams and served as a senior executive in Genentech and other biopharmaceutical companies, and he has rich and professional experience in basic and translational medicine research, biologics development and enterprise operation management. He has published several papers as the first author in Cell and other world-class journals with more than 3600 citations. He invented the innovative antibody engineering technology with antibody intelligent design and dynamic evolutionary screening platform. He led the development of a series of antibody drugs targeting malignancies, immune diseases and viral infections. He also participated in national key laboratory research projects, protein machine key projects, precision medicine research projects and other national scientific research projects.`,
            },
            {
              open: false,
              name: "Dr.Yun Li",
              title: "CSO and General Manager",
              desc: `Dr. Li got Ph.D. in molecular and cell biology from University of Pennsylvania, USA, and she was under the tutelage of Distinguished Fellow of the National Academy of Sciences. She ever worked for Genentech, a world-renowned biopharmaceutical company, and led the research and development of several protein drugs including monoclonal antibodies and bispecific antibodies for the treatment of tumor and immune diseases. She has over 20 years of experience in innovative antibody research and development, specializing in tumor therapy and immune-related diseases, and she has published several papers in world-class journals such as Cell and Nature as the first author. `,
            },
            // {
            //   open: false,
            //   name: "YaPing Yang",
            //   title: "General Manager of Bioactivity",
            //   // desc: "Graduated from Peking University in Pharmacology, YaPing Yang is responsible for biological mechanism analysis, preclinical pharmacodynamics and pharmacokinetics analysis, drug safety evaluation, as well as translational medicine research. He has been in the biologics development industry over 13 years with extensive experience in bioactivity and biomechanism analysis, pharmacokinetic analysis, in vivo drug efficacy and toxicity evaluation. Prior to FutureGen, he plays a critical role in large-scaled pharmaceutical companies.",
            // },
            // {
            //   open: false,
            //   name: "XiuMei Jin",
            //   title: "Senior Director of Quality & Analysis",
            //   // desc: "Graduated from Shenyang Pharmaceutical University in pharmacy, XiuMei Jin joined FutureGen in 2018 and as Quality & Analysis Senior Director, she is mainly responsible for quality control, quality assurance, molecular assessment and analysis and biologics technology. With rich experience in macromolecular characterization analysis, she excels at providing overall guidance for quality control and analytical technology, leading the team to overcome technical difficulties.",
            // },
            // {
            //   open: false,
            //   name: "WenYing Tang",
            //   title: "Senior Director of CMC",
            //   // desc: "Graduated from China Agricultural University in Biochemistry and Molecular Biology, with more than 10 years of biologics working experience in process development from upstream to downstream and pilot magnification, WenYing Tang has involved in several projects of preclinical CMC development and IND filings including monoclonal antibodies, bispecific antibodies, fusion protein and cytokines. She is familiar with the overall CMC process from DNA to BLA and to IND, those projects have entered in Phrase Ⅰto Ⅲ clinical trial. She is also good at managing the whole process of CMC development and projects execution.",
            // },
            // {
            //   open: false,
            //   name: "YuBin Zhou",
            //   title: "General Manager of Medical",
            //   // desc: "Graduated from Peking University in Medical Immunology, YuBin Zhou has been in the pharmaceutical industry for over 12 years with extensive experience in the design and execution of clinical studies. He ever led project teams to get clinical trials completed and pushed 8 products launching and 3 products to NDA stage. Also he has good cooperation with experts from clinical, pharmacology, testing, statistics and CRO.",
            // },
            // {
            //   open: false,
            //   name: "Sandy Huang",
            //   title: "General Manager of Business Development",
            //   // desc: "Graduated from the University of Southern California in Pharmacy, Sandy Huang has over 18 years experience in the pharmaceutical industry. She ever accelerated 10 products launched, participated in more than 600 projects evaluation and business development with big pharma in China and top MNCs, including potential fundraising, investment, M&A projects, licensing and co-development deals. She previously worked at Eli Lilly, Roche, Bayer, Takeda, Henlius and 3SBio, and is mainly engaged in clinical research, marketing, new product planning, strategic planning and business development.",
            // },
            // {
            //   open: false,
            //   name: "Bin Shao",
            //   title: "Senior Director of Human Resource",
            //   // desc: "Graduated from University of Hong Kong in Organization and Human Resource Management, Bin Shao is mainly responsible for overall human resources management, organization development, corporate branding and marketing and administrative management of FutureGen. With over 13 years experience of human resources including 8 years in large-scaled pharmaceutical company, he is good at talent team building, recruitment and corporate culture construction.",
            // },
            // {
            //   open: false,
            //   name: "JianPing Ku",
            //   title: "Associate Director of Finance",
            //   // desc: "Graduated from China University of Petroleum majoring in accounting, JianPing Ku has more than 20 years of experience in financial management from financial internal control, budget management, cash flow management, financial analysis to risk prevention. She is good at IPO capital operation (including listing planning, enterprise restructuring, investment and financing, etc.), and financial management system construction and optimization to match the business of the enterprise.",
            // },
          ],
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
  methods: {
    showDetails(index) {
      if (navigator.userAgent.toLowerCase().indexOf("iphone") < 0 && navigator.userAgent.toLowerCase().indexOf("android") < 0) {
        if (index !== 0 && index !== 1) {
          for (let the of this.list) {
            the.open = false;
          }
          this.list[index].open = true;
        }
      } else {
        for (let the of this.list) {
          the.open = false;
        }
        this.list[index].open = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/tuandui.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.container {
  margin: 100px auto;
  width: 100%;
  max-width: 1200px;
  .list {
    width: 100%;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
    position: relative;
    li {
      width: 20%;
      margin: 60px 5% 0 0;
      flex: none;
      cursor: pointer;
      transition: all 0.3s ease-out;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      .box {
        width: 100%;
        margin: 0 auto;
        font-size: 0;
        padding: 0;
        img {
          width: 100%;
        }
      }
      div {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        h3 {
          color: #203864ff;
          font-size: 25px;
          margin: 15px 0;
          position: relative;
          width: 100%;
          display: block;
        }
        h3:after {
          content: "";
          background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/arrow.png") no-repeat;
          width: 72px;
          height: 29px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        span {
          color: #11182cff;
          font-size: 16px;
        }
        p {
          line-height: 1.6;
          color: #5e6880ff;
          margin-top: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          opacity: 0;
          height: 0;
        }
      }
    }
    li:nth-of-type(1) {
      border-bottom: 1px solid #e9e9e9ff;
    }
    li:nth-of-type(1),
    li:nth-of-type(2) {
      width: 100%;
      margin: 0;
      padding: 50px 0;
      display: flex;
      justify-content: space-between;
      .box {
        width: 27%;
        margin: 0;
      }
      div {
        width: 70%;
        margin: 0;
        p {
          overflow: initial;
          display: block;
          font-size: 20px;
          clear: both;
          padding-top: 20px;
          opacity: 1;
          height: auto;
        }
        h3 {
          font-size: 28px;
          float: left;
          width: auto;
          margin-right: 1em;
        }
        h3::after {
          display: none;
        }
        span {
          font-size: 28px;
          float: left;
          margin: 15px 0;
          color: #203864ff;
          border-left: 3px solid;
          font-weight: bold;
          padding-left: 1em;
        }
      }
    }
    li.selected {
      transform: scale(1.05);
      box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.35);
      div:not(.box) {
        padding: 15px;
      }
      div {
        background: #203864ff;
        h3 {
          margin: 0 0 15px 0;
        }
        h3,
        span,
        p {
          color: white;
        }
        p {
          overflow: inherit;
          -webkit-line-clamp: inherit;
          opacity: 1;
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .container {
    margin: 3vh auto;
    width: 100%;
    max-width: 90vw;
    .list {
      li {
        width: 100%;
        margin: 5vh 0;
        div {
          h3 {
            color: #203864ff;
            font-size: 18px;
            margin: 1vh 0;
          }
          h3:after {
            background-size: contain;
            width: 42px;
            height: 19px;
          }
          span {
            color: #11182cff;
            font-size: 12px;
          }
        }
      }
      li:nth-of-type(1) {
        border: none;
      }
      li:nth-of-type(1),
      li:nth-of-type(2) {
        width: 100%;
        margin: 5vh 0;
        padding: 0;
        display: block;
        .box {
          width: 100%;
          margin: 0;
        }
        div {
          width: 100%;
          margin: 0 auto;
          p {
            padding-top: 0;
            opacity: 0;
            height: 0;
          }
          h3 {
            font-size: 18px;
            float: none;
            width: auto;
            margin: 1vh 0;
          }
          h3::after {
            display: block;
          }
          span {
            color: #11182cff;
            font-size: 12px;
            float: none;
            margin: 0;
            border: none;
            font-weight: normal;
            padding: 0;
          }
        }
      }
      li.selected {
        transform: scale(1.05);
        box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.35);
        div:not(.box) {
          padding: 15px;
        }
        div {
          background: #203864ff;
          h3 {
            margin: 0 0 15px 0;
          }
          h3,
          span,
          p {
            color: white;
          }
          p {
            overflow: inherit;
            -webkit-line-clamp: inherit;
            font-size: 14px;
            opacity: 1;
            height: auto;
          }
        }
      }
    }
  }
}
</style>
